/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, localeFromPath, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'
import {
  ParticipantStatus,
  RemoteParticipantStatus,
  participantStatusForSaveMapping,
  participantStatusMapping
} from '../recruitment'

export enum PaymentStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Processing = 'PROCESSING',
  Cancelled = 'CANCELLED',
  Rejected = 'Rejected',
  Error = 'ERROR',
  Paid = 'PAID'
}

export interface Payment {
  id: string
  participantId: string
  datacaptId: string
  firstName: string
  lastName: string
  email: string
  creationDate: Dayjs
  paymentDate: Dayjs
  stage: ParticipantStatus
  status: PaymentStatus
  visitName: string
  scheduleName: string
  amount: number
  amountDefault: number
  comment: string
  currency: string
  tax?: number
}

export interface PaymentSorter {
  field: keyof Payment
  order: SorterOrder
}

const sorterFields = {
  id: ['datacapt_id'],
  firstName: ['first_name', 'last_name'],
  email: ['email'],
  creationDate: ['created_at'],
  paymentDate: ['payment_date'],
  stage: ['recruitment_record_status'],
  status: ['status']
}

export interface RemotePayment {
  id: number
  recruitment_record_id: number
  datacapt_id: string
  first_name: string
  last_name: string
  email: string
  created_at: string
  payment_date: string
  recruitment_record_status: RemoteParticipantStatus
  status: PaymentStatus
  visit_name: string
  schedule_name: string
  amount: number
  amount_default: number
  comment: string
  currency: string
  tax?: number
}

interface FetchPaymentsResponse {
  count: number
  next: number
  results: RemotePayment[]
}

const parseRemotePayment: (payment: RemotePayment) => Payment = (payment: RemotePayment) => ({
  id: String(payment.id),
  participantId: String(payment.recruitment_record_id),
  datacaptId: payment.datacapt_id,
  firstName: payment.first_name,
  lastName: payment.last_name,
  email: payment.email,
  creationDate: payment.created_at && dayjs(payment.created_at, 'YYYY-MM-DD').locale(localeFromPath()),
  paymentDate: payment.payment_date && dayjs(payment.payment_date, 'YYYY-MM-DD').locale(localeFromPath()),
  stage: participantStatusMapping[payment.recruitment_record_status],
  status: payment.status,
  visitName: payment.visit_name,
  scheduleName: payment.schedule_name,
  amount: payment.amount,
  amountDefault: payment.amount_default,
  comment: payment.comment,
  currency: payment.currency,
  tax: payment.tax
})

const preparePaymentForSave = (payment: Partial<Payment>) => ({
  ...(typeof payment.amount !== 'undefined' ? { amount: payment.amount } : {}),
  ...(typeof payment.paymentDate !== 'undefined'
    ? { payment_date: dayjs(payment.paymentDate).format('YYYY-MM-DD HH:mm') }
    : {}),
  ...(typeof payment.status !== 'undefined' ? { status: payment.status } : {})
})

interface FetchPaymentsOptions {
  studyId?: string
  options?: {
    limit?: number
    offset?: number
    sorter?: PaymentSorter
    search?: string
    filters?: {
      status?: string[]
      stage?: ParticipantStatus[]
      visit?: string[]
      creation_date_after?: string
      creation_date_before?: string
      payment_date_after?: string
      payment_date_before?: string
    }
  }
}

interface FetchPaymentsResponseHandlers {
  onSuccess?: ({ payments, allPaymentsCount }: { payments: Payment[]; allPaymentsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchPayments = (
  { studyId, options }: FetchPaymentsOptions,
  responseHandlers?: FetchPaymentsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, PaymentSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status,
    stage: options.filters?.stage?.map(s => participantStatusForSaveMapping[s]),
    visit: options.filters?.visit,
    creation_date_after: options.filters?.creation_date_after,
    creation_date_before: options.filters?.creation_date_before,
    payment_date_after: options.filters?.payment_date_after,
    payment_date_before: options.filters?.payment_date_before
  }

  const { req, cancel } = fetchApi.get<FetchPaymentsResponse>(
    `payments${studyId ? `/recruitment/${studyId}` : ''}`,
    query
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPaymentsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        payments: body.results.map(parseRemotePayment),
        allPaymentsCount: body.count
      })
    }
  })

  return cancel
}

interface updatePaymentDetailsOptions {
  paymentId: string
  payment: Partial<Payment>
}

interface updatePaymentDetailsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updatePaymentDetails = (
  { paymentId, payment }: updatePaymentDetailsOptions,
  responseHandlers: updatePaymentDetailsResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`payments/${paymentId}`, preparePaymentForSave(payment))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<updatePaymentDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdatePaymentsStatusResponseHandlers {
  onSuccess?: () => void
  onWrongStatus?: () => void
  onRequestError?: (code: number) => void
}

interface BulkUpdatePaymentsStatusOptions {
  search: string
  records: SelectionKeys
  status: string[]
  targetStatus: PaymentStatus
}

export const bulkUpdatePaymentsStatus = (
  { targetStatus, search, status, records }: BulkUpdatePaymentsStatusOptions,
  responseHandlers?: UpdatePaymentsStatusResponseHandlers
) => {
  const path = `payments/status`
  const query = {
    search: search || undefined,
    payments: records,
    status: status?.length ? status.join(',') : undefined,
    target_status: targetStatus
  }
  const { req, cancel } = fetchApi.patch(path, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdatePaymentsStatusResponseHandlers>(
        {
          [BackendError.PAYMENT_STATUS_ILLEGAL_TRANSITION]: 'onWrongStatus'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface updatePaymentStatusOptions {
  paymentId: string
  status: PaymentStatus
}

export const updatePaymentStatus = (
  { paymentId, status }: updatePaymentStatusOptions,
  responseHandlers: UpdatePaymentsStatusResponseHandlers
) => {
  return bulkUpdatePaymentsStatus(
    { search: '', status: [], records: [paymentId], targetStatus: status },
    responseHandlers
  )
}

interface ConfirmPaymentsResponseHandlers {
  onSuccess?: (noAccountDetailsCount?: number) => void
  onWrongStatus?: () => void
  onRequestError?: (code: number) => void
}

interface BulkConfirmPaymentsOptions {
  search: string
  records: SelectionKeys
}

interface BulkConfirmPaymentResponse {
  no_account_details_count?: number
}

export const bulkConfirmPayments = (
  { search, records }: BulkConfirmPaymentsOptions,
  responseHandlers?: ConfirmPaymentsResponseHandlers
) => {
  const path = `payments/confirm`
  const query = {
    search: search || undefined,
    payments: records
  }
  const { req, cancel } = fetchApi.patch<BulkConfirmPaymentResponse>(path, query)

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<ConfirmPaymentsResponseHandlers>(
        {
          [BackendError.PAYMENT_STATUS_ILLEGAL_TRANSITION]: 'onWrongStatus'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.no_account_details_count)
    }
  })

  return cancel
}

interface confirmPaymentOptions {
  paymentId: string
}

interface confirmPaymentResponseHandlers {
  onSuccess?: (no_account_details_count: number) => void
  onRequestError?: (code: number) => void
}

export const confirmPayment = (
  { paymentId }: confirmPaymentOptions,
  responseHandlers: confirmPaymentResponseHandlers
) => {
  return bulkConfirmPayments({ search: '', records: [paymentId] }, responseHandlers)
}
