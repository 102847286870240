/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import { ParticipantStatus, RemoteParticipantStatus, clearRemoteParticipantStatus } from '../recruitment/participants'
import { RecruitmentStudyStatus } from '../recruitment/recruitmentStudies'
import { RecordStatus } from '../sectionAdvancement'
import { StudyStatus } from '../studies'

type RemoteRepositoryProfileSubjectStatus = RecordStatus | RemoteParticipantStatus
type RepositoryProfileSubjectStatus = RecordStatus | ParticipantStatus
export type RepositoryProfileStudyStatus = StudyStatus | RecruitmentStudyStatus

interface RemoteRepositoryProfileStudy {
  enrolment_date: string
  application_date: string
  name: string
  reference_number: string
  status: RepositoryProfileStudyStatus
  record_status: RemoteRepositoryProfileSubjectStatus
}

export interface RepositoryProfileStudy {
  name: string
  reference: string
  enrolmentDate: string
  applicationDate: string
  studyStatus: RepositoryProfileStudyStatus
  recordStatus: RepositoryProfileSubjectStatus
}

const parseRemoteStudy = (study: RemoteRepositoryProfileStudy): RepositoryProfileStudy => {
  return {
    name: study.name,
    reference: study.reference_number,
    enrolmentDate: study.enrolment_date,
    applicationDate: study.application_date,
    studyStatus: study.status,
    recordStatus: clearRemoteParticipantStatus<RecordStatus>(study.record_status)
  }
}

interface StudiesRepositoryProfileFilters {
  status?: RepositoryProfileStudyStatus[]
}

const prepareStudiesFilters = (filters?: StudiesRepositoryProfileFilters) => {
  return filters?.status ? filters.status.join(',') : null
}

interface FetchStudiesOptions {
  options?: {
    filters?: StudiesRepositoryProfileFilters
    search?: string
    subjectId?: string
  }
}

interface FetchRepositoryProfileStudiesResponseHandlers {
  onSuccess?: (studies: RepositoryProfileStudy[]) => void
  onRequestError?: (code: number) => void
}

export const fetchRepositoryProfileStudies = (
  { options }: FetchStudiesOptions,
  responseHandlers?: FetchRepositoryProfileStudiesResponseHandlers
) => {
  const query = {
    status: prepareStudiesFilters(options?.filters),
    search: options?.search?.toLowerCase()
  }
  const { req, cancel } = fetchApi.get<RemoteRepositoryProfileStudy[]>(
    `subject_repository/subjects/${options.subjectId}/studies`,
    query
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRepositoryProfileStudiesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(study => parseRemoteStudy(study)))
    }
  })

  return cancel
}
