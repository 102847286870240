/* eslint-disable camelcase */
import { createErrorsHandlers, getLanguage } from '../../utils'
import { fetchApi } from '../fetchApi'
import { fetchOpenEndpointFilesUrls } from '../file'
import {
  CommonSurveyStatus,
  FileAnswer,
  RemoteFileAnswer,
  SurveyAnswer,
  SurveyData,
  SurveyRemoteBlocks,
  SurveyRemoteQuestionAnswer,
  deleteAnswer,
  deleteFileAnswer,
  downloadFileAnswer,
  fetchConditionalLogic,
  parseRemoteFileAnswer,
  parseRemoteSurveyAnswer,
  parseSurveyBlocks,
  saveAnswer,
  saveFileAnswer
} from '../forms'
import { getSubjectRepositoryAnswerPath, subjectRepositoryAnswerMapping } from './fulfillment'

export interface RemoteSubjectRepositorySurvey {
  subject_id: string
  status: CommonSurveyStatus
  blocks: SurveyRemoteBlocks[]
}

interface FetchSubjectRepositorySurveyResponseHandlers {
  onSuccess?: (survey: SurveyData) => void
  onNotFound?: () => void
  onExpired?: () => void
  onUnauthorized?: () => void
  onRequestError?: () => void
}

export const fetchSubjectRepositorySurvey = (
  { subjectId }: { subjectId: string },
  responseHandlers?: FetchSubjectRepositorySurveyResponseHandlers
) => {
  const url = `subject_accounts/subjects/fulfillment/${subjectId}`
  const { req, cancel } = fetchApi.get<RemoteSubjectRepositorySurvey>(url, {}, { language: getLanguage() })

  req.then(({ error, body, status }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        subjectId: body.subject_id,
        status: body.status,
        ...parseSurveyBlocks(body.blocks)
      })
      return
    }

    if (status === 401 && responseHandlers?.onUnauthorized) {
      responseHandlers.onUnauthorized()
      return
    }

    if (status === 404 && responseHandlers?.onNotFound) {
      responseHandlers.onNotFound()
      return
    }

    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

interface SubjectRepositoryAnswerFields {
  subjectId: string
}

const getFilePath = () => 'uploaded_files/subject_repository/answer'

export const saveSubjectRepositorySurveyAnswer = saveAnswer<
  SubjectRepositoryAnswerFields,
  SurveyRemoteQuestionAnswer,
  SurveyAnswer
>(getSubjectRepositoryAnswerPath, subjectRepositoryAnswerMapping, parseRemoteSurveyAnswer)

export const deleteSubjectRepositorySurveyAnswer = deleteAnswer<SubjectRepositoryAnswerFields>(
  getSubjectRepositoryAnswerPath,
  subjectRepositoryAnswerMapping
)

export const saveSubjectRepositoryFileAnswer = saveFileAnswer<
  SubjectRepositoryAnswerFields,
  RemoteFileAnswer,
  FileAnswer
>(getFilePath, subjectRepositoryAnswerMapping, parseRemoteFileAnswer)

export const saveSubjectRepositorySurveyFileAnswer = saveFileAnswer<
  SubjectRepositoryAnswerFields,
  SurveyRemoteQuestionAnswer,
  SurveyAnswer
>(getFilePath, subjectRepositoryAnswerMapping, parseRemoteSurveyAnswer)

export const deleteSubjectRepositoryFileAnswer = deleteFileAnswer<SubjectRepositoryAnswerFields>(
  getFilePath,
  subjectRepositoryAnswerMapping
)

export const fetchSubjectRepositoryFilesUrls = fetchOpenEndpointFilesUrls('uploaded_files/subject_repository/subject')

export const downloadSubjectRepositoryFileAnswer = downloadFileAnswer(getFilePath())

export const fetchSubjectRepositoryConditionalLogic = fetchConditionalLogic(
  () => 'subject_repository/conditional_logic'
)

interface CompleteSubjectRepositorySurveyResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const completeSubjectRepositorySurvey = (
  { subjectId }: { subjectId: string },
  responseHandlers?: CompleteSubjectRepositorySurveyResponseHandlers
) => {
  const url = `subject_accounts/subjects/fulfillment/${subjectId}`
  const { req, cancel } = fetchApi.patch(url, { survey_status: CommonSurveyStatus.Completed })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CompleteSubjectRepositorySurveyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
