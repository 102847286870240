/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../../../utils'
import { Feature } from '../../../Feature'
import { fetchApi } from '../../../fetchApi'
import { RemoteSection, Section, Structure, parseRemoteSection } from '../../structure'
import { parseRemoteConditionalLogic, prepareQuestionConditionalLogicForSave } from '../conditionalLogic'
import { StaticContentHeader, StaticContentImage, StaticContentInstruction, StaticContentType } from '../staticContent'
import { parseRemoteValidation, prepareValidationForSave } from '../validationRules'
import { QuestionType, RemoteQuestionFromStudy } from './base'
import {
  CalculQuestion,
  RemoteCalculQuestion,
  parseCalculTypeRemoteQuestionConfig,
  prepareCalculQuestionConfigForSave
} from './calcul'
import {
  DateTimeQuestion,
  RemoteDateTimeQuestion,
  parseDateTimeTypeRemoteQuestionConfig,
  prepareDateTimeQuestionConfigForSave
} from './datetime'
import { FileQuestion } from './file'
import {
  LsaQuestion,
  RemoteLsaQuestion,
  parseLsaTypeRemoteQuestionConfig,
  prepareLsaQuestionConfigForSave
} from './lsa'
import {
  NumberQuestion,
  RemoteNumberQuestion,
  parseNumberTypeRemoteQuestionConfig,
  prepareNumberQuestionConfigForSave
} from './number'
import {
  RatingQuestion,
  RemoteRatingQuestion,
  parseRatingTypeRemoteQuestionConfig,
  prepareRatingQuestionConfigForSave
} from './rating'
import {
  CheckboxQuestion,
  DropdownQuestion,
  RadioQuestion,
  RemoteSelectQuestion,
  parseSelectTypeRemoteQuestionConfig,
  prepareSelectQuestionConfigForSave
} from './select'
import {
  RemoteSliderQuestion,
  SliderQuestion,
  parseSliderTypeRemoteQuestionConfig,
  prepareSliderQuestionConfigForSave
} from './slider'
import {
  RemoteTextQuestion,
  TextQuestion,
  parseTextTypeRemoteQuestionConfig,
  prepareTextQuestionConfigForSave
} from './text'

export type RemoteQuestion =
  | RemoteTextQuestion
  | RemoteSelectQuestion
  | RemoteDateTimeQuestion
  | RemoteNumberQuestion
  | RemoteRatingQuestion
  | RemoteCalculQuestion
  | RemoteSliderQuestion
  | RemoteLsaQuestion

export type Question =
  | TextQuestion
  | RadioQuestion
  | CheckboxQuestion
  | DateTimeQuestion
  | NumberQuestion
  | FileQuestion
  | RatingQuestion
  | CalculQuestion
  | DropdownQuestion
  | SliderQuestion
  | LsaQuestion

export interface QuestionTypeMapping {
  [QuestionType.Text]: TextQuestion
  [QuestionType.Rating]: RatingQuestion
  [QuestionType.Radio]: RadioQuestion
  [QuestionType.Number]: NumberQuestion
  [QuestionType.Checkbox]: CheckboxQuestion
  [QuestionType.DateTime]: DateTimeQuestion
  [QuestionType.File]: FileQuestion
  [QuestionType.Rating]: RatingQuestion
  [QuestionType.Calcul]: CalculQuestion
  [QuestionType.Dropdown]: DropdownQuestion
  [QuestionType.Slider]: SliderQuestion
  [QuestionType.Lsa]: LsaQuestion
  [StaticContentType.Header]: StaticContentHeader
  [StaticContentType.Image]: StaticContentImage
  [StaticContentType.Instruction]: StaticContentInstruction
}

const parseRemoteQuestionConfig = (question: RemoteQuestion) => {
  switch (question.type) {
    case QuestionType.Radio:
    case QuestionType.Checkbox:
    case QuestionType.Dropdown:
      return parseSelectTypeRemoteQuestionConfig(question.config)
    case QuestionType.Rating:
      return parseRatingTypeRemoteQuestionConfig(question.config)
    case QuestionType.Slider:
      return parseSliderTypeRemoteQuestionConfig(question.config)
    case QuestionType.Number:
      return parseNumberTypeRemoteQuestionConfig(question.config)
    case QuestionType.Calcul:
      return parseCalculTypeRemoteQuestionConfig(question.config)
    case QuestionType.DateTime:
      return parseDateTimeTypeRemoteQuestionConfig(question.config)
    case QuestionType.Text:
      return parseTextTypeRemoteQuestionConfig(question.config)
    case QuestionType.Lsa:
      return parseLsaTypeRemoteQuestionConfig(question.config)
    default:
      return null
  }
}

const parseRemoteQuestionValidationRules = (question: RemoteQuestion) => {
  switch (question.type) {
    case QuestionType.Number:
    case QuestionType.Calcul:
    case QuestionType.Rating:
    case QuestionType.Slider:
      return parseRemoteValidation(question.validation_rules, QuestionType.Number)
    case QuestionType.Radio:
    case QuestionType.Dropdown:
      return parseRemoteValidation(question.validation_rules, QuestionType.Radio)
    case QuestionType.DateTime:
      return parseRemoteValidation(question.validation_rules, QuestionType.DateTime)
    default:
      return null
  }
}

export const parseRemoteQuestionFromStudy = (remoteReminderQuestion: RemoteQuestionFromStudy) => ({
  referenceQuestionBuilderId: `${remoteReminderQuestion.builder_type}.${remoteReminderQuestion.builder_id}`,
  referenceQuestionId: String(remoteReminderQuestion.question_id)
})

export const parseRemoteQuestion = (question: RemoteQuestion) => {
  const config = parseRemoteQuestionConfig(question)
  const dataValidation = parseRemoteQuestionValidationRules(question)
  const conditionalLogic = parseRemoteConditionalLogic(question.conditional_logic)
  const reminderQuestions = question.reminder_questions
    ? question.reminder_questions.map(parseRemoteQuestionFromStudy)
    : null
  return {
    id: String(question.id),
    title: question.title,
    description: question.description,
    descriptionImage: question.description_image ? { fileId: question.description_image } : undefined,
    type: question.type,
    variableName: question.variable || question.variable_full,
    config,
    dataValidation,
    conditionalLogic,
    reminderQuestions,
    excludeFromExport: question.exclude_from_export,
    hideInEcrf: question.hide_in_ecrf,
    visibleForRoles: question.visible_for_roles,
    editableForRoles: question.editable_for_roles,
    required: !!question.required,
    repeatedMeasure: question.repeated_measure ? String(question.repeated_measure) : null,
    showInSurvey: question.show_in_survey,
    showInProfile: question.show_in_profile,
    builderType: question.builder_type,
    isGlobal: question.is_global
  } as Question
}

const prepareQuestionConfigForSave = (question: Question) => {
  switch (question.type) {
    case QuestionType.Checkbox:
    case QuestionType.Radio:
    case QuestionType.Dropdown:
      return prepareSelectQuestionConfigForSave(question.config, question.type)
    case QuestionType.Text:
      return prepareTextQuestionConfigForSave(question.config)
    case QuestionType.DateTime:
      return prepareDateTimeQuestionConfigForSave(question.config)
    case QuestionType.Number:
      return prepareNumberQuestionConfigForSave(question.config)
    case QuestionType.Rating:
      return prepareRatingQuestionConfigForSave(question.config)
    case QuestionType.Calcul:
      return prepareCalculQuestionConfigForSave(question.config)
    case QuestionType.Slider:
      return prepareSliderQuestionConfigForSave(question.config)
    case QuestionType.Lsa:
      return prepareLsaQuestionConfigForSave(question.config)
    default:
      return {}
  }
}

const prepareQuestionValidationForSave = (question: Question) => {
  switch (question.type) {
    case QuestionType.Number:
    case QuestionType.Calcul:
    case QuestionType.Rating:
    case QuestionType.Slider:
      return prepareValidationForSave(question.dataValidation?.validationRules, QuestionType.Number)
    case QuestionType.Radio:
    case QuestionType.Dropdown:
      return prepareValidationForSave(question.dataValidation?.validationRules, QuestionType.Radio)
    case QuestionType.DateTime:
      return prepareValidationForSave(question.dataValidation?.validationRules, QuestionType.DateTime)
    default:
      return []
  }
}

export const prepareQuestionForSave = (
  question: Question,
  order: number,
  subsectionId: string,
  isRequiredNotApplicable?: boolean
) => {
  const getRequired = () => {
    if (isRequiredNotApplicable) return undefined
    if (question.hideInEcrf) return false
    return !!question.required
  }
  return {
    id: question.id,
    title: question.title,
    description: question.description || '',
    description_image: question.descriptionImage?.fileId || null,
    type: question.type,
    subsection: subsectionId,
    config: prepareQuestionConfigForSave(question),
    variable: question.variableName,
    validation_rules: prepareQuestionValidationForSave(question),
    conditional_logic:
      question.conditionalLogic?.[0] && prepareQuestionConditionalLogicForSave(question.conditionalLogic[0]),
    reminder_questions: question.reminderQuestions?.map(question => question.referenceQuestionId) || null,
    exclude_from_export: question.excludeFromExport,
    hide_in_ecrf: question.hideInEcrf,
    visible_for_roles: question.visibleForRoles || null,
    editable_for_roles: question.editableForRoles || null,
    order,
    required: getRequired(),
    show_in_profile: !!question.showInProfile,
    show_in_survey: !!question.showInSurvey,
    is_global: question.isGlobal
  }
}

interface RemoteQuestionsFromStudy {
  builder_id: string
  name: string
  builder_type: Feature
  sections: RemoteSection[]
}

export interface QuestionsFromStudy {
  builderId: string
  name: string
  builderType: Feature
  sections: Section[]
}

export const parsedQuestionFromStudyId = (question: QuestionsFromStudy) =>
  question && `${question.builderType}.${question.builderId}`

const parseRemoteQuestionsFromStudy = (remoteQuestionFromStudy: RemoteQuestionsFromStudy) => ({
  builderId: String(remoteQuestionFromStudy.builder_id),
  name: remoteQuestionFromStudy.name,
  builderType: remoteQuestionFromStudy.builder_type,
  sections: !!remoteQuestionFromStudy.sections.length && remoteQuestionFromStudy.sections.map(parseRemoteSection)
})

export interface InvalidBlock {
  blockId: string
  sectionId: string
  subsectionId: string
  repeatedMeasureId: string
  isSectionInvalid: boolean
  isSubsectionInvalid: boolean
}

export const parseRemoteInvalidBlocks = (allBlocks: InvalidBlock[], section: RemoteSection) => {
  if (!section.subsections.length)
    return [
      ...allBlocks,
      {
        sectionId: String(section.id),
        isSectionInvalid: section.invalid,
        subsectionId: null,
        isSubsectionInvalid: false,
        blockId: null,
        repeatedMeasureId: null
      }
    ]

  return section.subsections.reduce((allBlocks: InvalidBlock[], subsection) => {
    if (!subsection.blocks.length)
      return [
        ...allBlocks,
        {
          sectionId: String(section.id),
          isSectionInvalid: section.invalid,
          subsectionId: String(subsection.id),
          isSubsectionInvalid: subsection.invalid,
          blockId: null,
          repeatedMeasureId: null
        }
      ]

    return [
      ...allBlocks,
      ...subsection.blocks.map(block => ({
        blockId: String(block.id),
        sectionId: String(section.id),
        isSectionInvalid: section.invalid,
        subsectionId: String(subsection.id),
        isSubsectionInvalid: subsection.invalid,
        repeatedMeasureId: (block as RemoteQuestion).repeated_measure_id
          ? String((block as RemoteQuestion).repeated_measure_id)
          : null
      }))
    ]
  }, allBlocks)
}

interface QuestionsDependencies {
  conditionalQuestions: Structure
  dateTimes: Structure
  questionsFromStudy: QuestionsFromStudy[]
  invalidBlocks: InvalidBlock[]
  referenceQuestionsIds: string[]
}

interface FetchQuestionsDependenciesResponse {
  questions_for_conditional_logic: {
    sections: RemoteSection[]
  }
  questions_for_data_validation: {
    sections: RemoteSection[]
  }
  study_questions: RemoteQuestionsFromStudy[]
  blocks_with_errors: {
    sections: RemoteSection[]
  }
  reference_questions_ids: number[]
}

const parseQuestionsDependencies = (response: FetchQuestionsDependenciesResponse) => ({
  conditionalQuestions: {
    sections: response?.questions_for_conditional_logic?.sections.map(parseRemoteSection) || []
  },
  dateTimes: {
    sections: response?.questions_for_data_validation?.sections.map(parseRemoteSection) || []
  },
  questionsFromStudy: response?.study_questions?.length
    ? response.study_questions.map(parseRemoteQuestionsFromStudy)
    : [],
  invalidBlocks: response?.blocks_with_errors?.sections.reduce(parseRemoteInvalidBlocks, []),
  referenceQuestionsIds: response?.reference_questions_ids.map(String) || []
})

export interface FetchQuestionsDependenciesResponseHandlers {
  onSuccess?: (questionsDependencies: QuestionsDependencies) => void
  onRequestError?: (code: number) => void
}

export const fetchQuestionsDependencies =
  <T>(path: (params: T) => string) =>
  (params: T & { studyId: string }, responseHandlers?: FetchQuestionsDependenciesResponseHandlers) => {
    const headers = { studyId: params.studyId }
    const { req, cancel } = fetchApi.get<FetchQuestionsDependenciesResponse>(path(params), {}, headers)

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<FetchQuestionsDependenciesResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(parseQuestionsDependencies(body))
      }
    })

    return cancel
  }
