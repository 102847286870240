/* eslint-disable camelcase */
import { EconsentStatus } from '../econsent'
import {
  BaseQuestionAnswer,
  BaseRemoteQuestionAnswer,
  BlockType,
  DropdownQuestion,
  Question,
  QuestionBlock,
  QuestionType,
  RemoteBlockType,
  RemoteDateTimeQuestion,
  RemoteNumberQuestion,
  RemoteQuestionBlock,
  RemoteRatingQuestion,
  RemoteSelectQuestion,
  RemoteStaticContentBlock,
  RemoteTextQuestion,
  SliderQuestion,
  StaticContentBlock,
  parseDataValidation,
  parseRemoteQuestion,
  parseRemoteQuestionAnswer,
  parseRemoteStaticContent
} from '../forms'
import { LsaStatus } from '../lsa'

export enum CommonSurveyStatus {
  Uncompleted = 'UNCOMPLETED',
  Resent = 'RESENT',
  Completed = 'COMPLETED'
}

export type SurveyRemoteBlockType = RemoteBlockType.Question | RemoteBlockType.StaticContent

export interface RemoteSurveySelectQuestion extends RemoteSelectQuestion {
  type: QuestionType.Radio | QuestionType.Checkbox
}

export type SurveyQuestion = QuestionBlock<Exclude<Question, DropdownQuestion | SliderQuestion>>

export type SurveyBlock = SurveyQuestion | StaticContentBlock

export interface SurveyAnswer extends BaseQuestionAnswer {
  questionId: string
  recordId?: string
}

export interface SurveyData {
  subjectId: string
  surveyName?: string
  recordId?: number
  blocks: SurveyBlock[]
  answers: SurveyAnswer[]
  status?: EconsentStatus | CommonSurveyStatus
}

export interface SurveyRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  epro_record?: string
  econsent_record?: string
}

export type SurveyRemoteBlocks = RemoteQuestionBlock<RemoteSurveyQuestion> | RemoteStaticContentBlock

interface SurveyRemoteSubjectMeta {
  question: string
  lsa_status: LsaStatus
}

type RemoteSurveyQuestion = (
  | RemoteTextQuestion
  | RemoteDateTimeQuestion
  | RemoteNumberQuestion
  | RemoteRatingQuestion
  | RemoteSurveySelectQuestion
) & {
  block_type: SurveyRemoteBlockType
  answer: SurveyRemoteQuestionAnswer
  subject_meta?: SurveyRemoteSubjectMeta
}

export const parseRemoteSurveyAnswer = (answer: SurveyRemoteQuestionAnswer) => ({
  ...parseRemoteQuestionAnswer(answer),
  validationMessages: parseDataValidation(answer, true),
  recordId: String(answer.epro_record || answer.econsent_record),
  questionId: String(answer.question)
})

export const parseSurveyBlocks = (blocks: SurveyRemoteBlocks[]): { blocks: SurveyBlock[]; answers: SurveyAnswer[] } => {
  return blocks.reduce(
    (acc, next) => {
      switch (next.block_type) {
        case RemoteBlockType.Question:
          acc.blocks.push({
            ...parseRemoteQuestion(next as RemoteQuestionBlock<RemoteSurveyQuestion>),
            blockType: BlockType.Question
          })
          break
        case RemoteBlockType.StaticContent:
          acc.blocks.push({
            ...parseRemoteStaticContent(next as RemoteStaticContentBlock),
            blockType: BlockType.StaticContent
          })
          break
      }

      if (next.block_type === RemoteBlockType.Question && next.answer) {
        acc.answers.push(parseRemoteSurveyAnswer(next.answer))
      }

      if (
        next.block_type === RemoteBlockType.Question &&
        !next.answer &&
        next.subject_meta?.question === next.id &&
        next.subject_meta?.lsa_status === LsaStatus.Started
      ) {
        // we are adding empty answer for lsa question that is pending
        acc.answers.push({
          questionId: String(next.id),
          value: [{ name: '' }]
        })
      }
      return acc
    },
    { blocks: [], answers: [] }
  )
}

export type SaveDeleteAnswerHandlers = {
  onSuccess: (response?: SurveyAnswer) => void
  onRequestError: (code?: number) => void
}
