/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../utils'
import { SorterOrder } from './SorterOrder'
import { EproPeriods } from './epros'
import { fetchApi } from './fetchApi'
import {
  Condition,
  QuestionFromStudy,
  RemoteCondition,
  RemoteQuestionFromStudy,
  parseRemoteQuestionFromStudy
} from './forms/blocks'

export enum AutomationEvents {
  SubjectIncluded = 'SUBJECT_INCLUDED',
  SubjectExcluded = 'SUBJECT_EXCLUDED',
  Question = 'QUESTION',
  QueriesCreated = 'QUERIES_CREATED',
  EconsentSigned = 'ECONSENT_SIGNED',
  EconsentRejected = 'ECONSENT_REJECTED',
  SubjectRandomized = 'SUBJECT_RANDOMIZED',
  EproSent = 'EPRO_SENT'
}

export enum AutomationAction {
  EmailNotification = 'EMAIL_NOTIFICATION',
  SubjectEmailNotification = 'SUBJECT_EMAIL_NOTIFICATION',
  SendEpro = 'SEND_EPRO'
}

export type AutomationQuestion = QuestionFromStudy & Condition
export type RemoteAutomationQuestion = RemoteQuestionFromStudy & RemoteCondition

export interface Automation {
  id: string
  name: string
  event: AutomationEvents
  action: AutomationAction
  question: AutomationQuestion
  triggerEpro: string
  actionEpro: string
  amount: number
  unit: EproPeriods
  triggersCount: number
  lastUpdate: Date
  lastUpdateAuthor: string
  recipients: string[]
  subject: string
  message: string
}

export interface RemoteAutomation {
  id: number
  name: string
  last_updated: string
  last_updated_author: string
  event: AutomationEvents
  action: AutomationAction
  question: RemoteAutomationQuestion
  trigger_epro: number
  action_epro: number
  amount: number
  unit: EproPeriods
  triggers_count: number
  emails: string[]
  email_subject: string
  email_message: string
}

interface FetchAutomationsResponse {
  count: number
  next: number
  previous: number
  results: RemoteAutomation[]
}

const parseRemoteAutomationCondition = (question: RemoteAutomationQuestion) => {
  if (!question) return null
  return {
    referenceQuestionId: String(question.reference_question),
    operator: question.operator,
    answerValue: typeof question.answer_value === 'object' ? question.answer_value.selections : question.answer_value
  }
}

const prepareAutomationConditionForSave = (question: AutomationQuestion) => {
  if (!question) return null
  return {
    reference_question: question.referenceQuestionId,
    answer_value: {
      selections: question.answerValue
    },
    operator: question.operator
  }
}

const parseRemoteAutomationQuestion = (question: RemoteAutomationQuestion) => ({
  ...parseRemoteAutomationCondition(question),
  ...parseRemoteQuestionFromStudy(question as RemoteQuestionFromStudy)
})

const parseRemoteAutomation: (automation: RemoteAutomation) => Automation = (automation: RemoteAutomation) => ({
  id: String(automation.id),
  name: automation.name,
  lastUpdate: new Date(automation.last_updated),
  lastUpdateAuthor: automation.last_updated_author,
  event: automation.event,
  action: automation.action,
  question: automation.question ? parseRemoteAutomationQuestion(automation.question) : null,
  triggerEpro: String(automation.trigger_epro),
  actionEpro: String(automation.action_epro),
  amount: automation.amount,
  unit: automation.unit,
  triggersCount: automation.triggers_count,
  recipients: automation.emails,
  subject: automation.email_subject,
  message: automation.email_message
})

export interface AutomationsSorter {
  field: keyof Automation
  order: SorterOrder
}

const sorterFields = {
  lastUpdate: ['last_updated'],
  triggersCount: ['triggers_count']
}

interface FetchAutomationsOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: AutomationsSorter
    search?: string
  }
}

interface FetchAutomationsResponseHandlers {
  onSuccess?: ({ automations, allAutomationsCount }: { automations: Automation[]; allAutomationsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchAutomations = (
  { studyId, options }: FetchAutomationsOptions,
  responseHandlers?: FetchAutomationsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, AutomationsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search
  }

  const { req, cancel } = fetchApi.get<FetchAutomationsResponse>('automations', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAutomationsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        automations: body.results.map(parseRemoteAutomation),
        allAutomationsCount: body.count
      })
    }
  })

  return cancel
}

interface DeleteAutomationOptions {
  studyId: string
  automationId: string
}

interface DeleteAutomationResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteAutomation = (
  { studyId, automationId }: DeleteAutomationOptions,
  responseHandlers?: DeleteAutomationResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`automations/${automationId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteAutomationResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export type AutomationRequestOptions = Automation & { studyId: string }

interface AutomationRequestResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const addAutomation = (
  {
    studyId,
    name,
    event,
    question,
    triggerEpro,
    action,
    actionEpro,
    amount,
    unit,
    recipients,
    subject,
    message
  }: AutomationRequestOptions,
  responseHandlers?: AutomationRequestResponseHandlers
) => {
  const query = {
    study: studyId,
    name,
    event,
    ...(question ? { question: prepareAutomationConditionForSave(question) } : {}),
    trigger_epro: triggerEpro,
    action,
    action_epro: actionEpro,
    amount,
    unit,
    emails: recipients,
    email_subject: subject,
    email_message: message
  }
  const { req, cancel } = fetchApi.post('automations', query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AutomationRequestResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const editAutomation = (
  { id, studyId, recipients, subject, message, name, amount, unit }: AutomationRequestOptions,
  responseHandlers?: AutomationRequestResponseHandlers
) => {
  const query = {
    study: studyId,
    name,
    amount,
    unit,
    emails: recipients,
    email_subject: subject,
    email_message: message
  }
  const { req, cancel } = fetchApi.patch(`automations/${id}`, query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AutomationRequestResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
