/* eslint-disable camelcase */
import { createErrorsHandlers } from '../utils'
import { BackendError } from './RequestError'
import { fetchApi } from './fetchApi'
import { FileInfo, RemoteFile, parseRemoteFile } from './file'

export const MaxFoldersLevel = 5

export const DocumentSupportedExtensions = [
  'pdf',
  'docx',
  'xlsx',
  'png',
  'jpg',
  'jpeg',
  'csv',
  'doc',
  'xls',
  'ppt',
  'pptx'
]

interface CreateStudyDocumentOptions {
  studyId: string
  folderId: string
  file: File
}

interface CreateStudyDocumentResponseHandlers {
  onSuccess?: (savedFile: ReturnType<typeof parseRemoteFile>) => void
  onRequestError?: (code?: number) => void
}

export const createStudyDocument = (
  { file, studyId, folderId }: CreateStudyDocumentOptions,
  responseHandlers: CreateStudyDocumentResponseHandlers
) => {
  const { req, cancel } = fetchApi.postFile<RemoteFile>(
    'uploaded_files/documents',
    { file, ...(folderId && { folder: folderId }) },
    { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateStudyDocumentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteFile(body))
    }
  })

  return cancel
}

interface DeleteStudyDocumentOptions {
  studyId: string
  fileId: string
}

interface DeleteStudyDocumentResponseHandlers {
  onSuccess?: () => void
  onOwnedByAnotherUser?: () => void
  onRequestError?: (code?: number) => void
}

export const deleteStudyDocument = (
  { fileId, studyId }: DeleteStudyDocumentOptions,
  responseHandlers: DeleteStudyDocumentResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`uploaded_files/documents/${fileId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteStudyDocumentResponseHandlers>(
        {
          [BackendError.STUDY_DOCUMENT_OWNED_BY_ANOTHER_USER]: 'onOwnedByAnotherUser'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoteFetchStudyDocuments {
  id: string
  file_uuid: string
  file_name: string
  file_size: number
  uploaded_by: string
  date_added: string
  folder: number
}

const parseRemoteFetchStudyDocumentsResponse = (response: RemoteFetchStudyDocuments[]) => {
  const documents: FileInfo[] = response.map(document => ({
    id: document.id,
    uuid: document.file_uuid,
    name: document.file_name,
    uploadedBy: document.uploaded_by,
    dateAdded: new Date(document.date_added),
    parentId: document.folder && String(document.folder)
  }))
  return documents
}

interface FetchStudyDocumentsOptions {
  studyId: string
}
interface FetchStudyDocumentsResponseHandlers {
  onSuccess?: (documents: FileInfo[]) => void
  onRequestError?: (code: number) => void
}

export const fetchStudyDocuments = (
  { studyId }: FetchStudyDocumentsOptions,
  responseHandlers?: FetchStudyDocumentsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteFetchStudyDocuments[]>('uploaded_files/documents', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyDocumentsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteFetchStudyDocumentsResponse(body))
    }
  })

  return cancel
}

interface FetchStudyDocumentsUrlsOptions {
  studyId: string
  documentUuid: string
}

interface FetchStudyDocumentsUrlsResponse {
  url: string
}

interface FetchStudyDocumentsUrlsResponseHandlers {
  onSuccess?: (filesUrls: FetchStudyDocumentsUrlsResponse) => void
  onRequestError?: (code: number) => void
}

export const fetchStudyDocumentUrl = (
  { documentUuid, studyId }: FetchStudyDocumentsUrlsOptions,
  responseHandlers?: FetchStudyDocumentsUrlsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchStudyDocumentsUrlsResponse>(
    `uploaded_files/document/${documentUuid}`,
    {},
    { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyDocumentsUrlsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface RemoteDocumentsFolder {
  id: number
  name: string
  centers: number[]
  parent: string
  uploaded_by: string
  created_at: string
}

export interface DocumentsFolder {
  id?: string
  name: string
  centers: string[]
  parentId: string
  uploadedBy?: string
  dateAdded?: Date
}

const parseRemoteStudyDocumentsFolder = (remoteFolder: RemoteDocumentsFolder) => ({
  id: String(remoteFolder.id),
  name: remoteFolder.name,
  centers: remoteFolder.centers.map(id => String(id)),
  parentId: remoteFolder.parent && String(remoteFolder.parent),
  dateAdded: remoteFolder.created_at && new Date(remoteFolder.created_at),
  uploadedBy: remoteFolder.uploaded_by
})

interface FetchStudyFoldersOptions {
  studyId: string
}
interface FetchStudyFoldersResponseHandlers {
  onSuccess?: (folders: DocumentsFolder[]) => void
  onRequestError?: (code: number) => void
}

export const fetchStudyFolders = (
  { studyId }: FetchStudyFoldersOptions,
  responseHandlers?: FetchStudyFoldersResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteDocumentsFolder[]>('uploaded_files/folders', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyFoldersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteStudyDocumentsFolder))
    }
  })

  return cancel
}

interface CreateStudyDocumentsFolderOptions extends DocumentsFolder {
  studyId: string
}

interface CreateStudyDocumentsFolderResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const createStudyDocumentsFolder = (
  { name, centers, studyId, parentId }: CreateStudyDocumentsFolderOptions,
  responseHandlers?: CreateStudyDocumentsFolderResponseHandlers
) => {
  const { req, cancel } = fetchApi.post('uploaded_files/folders', { name, centers, parent: parentId }, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateStudyDocumentsFolderResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateStudyDocumentsFolderOptions extends DocumentsFolder {
  studyId: string
  folderId: string
}

interface UpdateStudyDocumentsFolderResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateStudyDocumentsFolder = (
  { name, centers, studyId, folderId }: UpdateStudyDocumentsFolderOptions,
  responseHandlers?: UpdateStudyDocumentsFolderResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`uploaded_files/folders/${folderId}`, { name, centers }, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateStudyDocumentsFolderResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface DeleteStudyFolderOptions {
  studyId: string
  folderId: string
}

interface DeleteStudyFolderResponseHandlers {
  onSuccess?: () => void
  onOwnedByAnotherUser?: () => void
  onRequestError?: (code?: number) => void
}

export const deleteStudyFolder = (
  { folderId, studyId }: DeleteStudyFolderOptions,
  responseHandlers: DeleteStudyFolderResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`uploaded_files/folders/${folderId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteStudyFolderResponseHandlers>(
        {
          [BackendError.STUDY_DOCUMENT_OWNED_BY_ANOTHER_USER]: 'onOwnedByAnotherUser'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface MoveStudyDocumentOptions {
  studyId: string
  fileId: string
  folderId: string
}

interface MoveStudyDocumentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code?: number) => void
}

export const moveStudyDocuments = (
  { fileId, folderId, studyId }: MoveStudyDocumentOptions,
  responseHandlers: MoveStudyDocumentResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`uploaded_files/documents/${fileId}`, { folder: folderId }, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<MoveStudyDocumentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
