/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'
import {
  RemoteSubjectInRepository,
  SimpleAnswer,
  SubjectInRepository,
  parseRemoteSimpleAnswer,
  parseRemoteSubject
} from '../subjectRepository'
import { InternationalPhoneNumber, parseRemotePhoneNumber } from '../subjects'
import { TableColumnVariableSource } from '../variables'

export enum RemoteParticipantStatus {
  New = 'NEW',
  Contacted = 'CONTACTED',
  NotInterested = 'NOT_INTERESTED',
  Interested = 'INTERESTED',
  Unqualified = 'UNQUALIFIED',
  QualifiedForScreening = 'QUALIFIED_FOR_SCREENING',
  Rejected = 'REJECTED',
  Enrolled = 'ENROLLED',
  Completed = 'COMPLETED'
}

export enum ParticipantStatus {
  Prospect = 'PROSPECT',
  Contacted = 'CONTACTED',
  NotInterested = 'NOT_INTERESTED',
  Interested = 'INTERESTED',
  Disqualified = 'DISQUALIFIED',
  Qualified = 'QUALIFIED',
  Enrolled = 'ENROLLED',
  Completed = 'COMPLETED',
  Excluded = 'EXCLUDED'
}

export const participantStatusMapping = {
  [RemoteParticipantStatus.New]: ParticipantStatus.Prospect,
  [RemoteParticipantStatus.Contacted]: ParticipantStatus.Contacted,
  [RemoteParticipantStatus.NotInterested]: ParticipantStatus.NotInterested,
  [RemoteParticipantStatus.Interested]: ParticipantStatus.Interested,
  [RemoteParticipantStatus.Unqualified]: ParticipantStatus.Disqualified,
  [RemoteParticipantStatus.QualifiedForScreening]: ParticipantStatus.Qualified,
  [RemoteParticipantStatus.Rejected]: ParticipantStatus.Excluded,
  [RemoteParticipantStatus.Enrolled]: ParticipantStatus.Enrolled,
  [RemoteParticipantStatus.Completed]: ParticipantStatus.Completed
}

export const participantStatusForSaveMapping = {
  [ParticipantStatus.Prospect]: RemoteParticipantStatus.New,
  [ParticipantStatus.Contacted]: RemoteParticipantStatus.Contacted,
  [ParticipantStatus.NotInterested]: RemoteParticipantStatus.NotInterested,
  [ParticipantStatus.Interested]: RemoteParticipantStatus.Interested,
  [ParticipantStatus.Disqualified]: RemoteParticipantStatus.Unqualified,
  [ParticipantStatus.Qualified]: RemoteParticipantStatus.QualifiedForScreening,
  [ParticipantStatus.Excluded]: RemoteParticipantStatus.Rejected,
  [ParticipantStatus.Enrolled]: RemoteParticipantStatus.Enrolled,
  [ParticipantStatus.Completed]: RemoteParticipantStatus.Completed
}

const participantStatusTransitionsMap = {
  [ParticipantStatus.Prospect]: [
    ParticipantStatus.Contacted,
    ParticipantStatus.NotInterested,
    ParticipantStatus.Interested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.Contacted]: [
    ParticipantStatus.NotInterested,
    ParticipantStatus.Interested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.NotInterested]: [
    ParticipantStatus.Contacted,
    ParticipantStatus.Interested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.Interested]: [
    ParticipantStatus.Contacted,
    ParticipantStatus.NotInterested,
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified
  ],
  [ParticipantStatus.Disqualified]: [
    ParticipantStatus.Qualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Completed,
    ParticipantStatus.Excluded
  ],
  [ParticipantStatus.Qualified]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Completed,
    ParticipantStatus.Excluded
  ],
  [ParticipantStatus.Excluded]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Completed
  ],
  [ParticipantStatus.Enrolled]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified,
    ParticipantStatus.Completed,
    ParticipantStatus.Excluded
  ],
  [ParticipantStatus.Completed]: [
    ParticipantStatus.Disqualified,
    ParticipantStatus.Qualified,
    ParticipantStatus.Enrolled,
    ParticipantStatus.Excluded
  ]
}

export const getPossibleParticipantTransitions = (selectedStatuses: ParticipantStatus[]) => {
  const unsortedPossibleTransitions = selectedStatuses
    .map(s => participantStatusTransitionsMap[s])
    .reduce((acc, curr) => acc.filter(s => curr.includes(s)))

  return (Object.values(ParticipantStatus) as ParticipantStatus[]).filter(s => unsortedPossibleTransitions.includes(s))
}

export const clearRemoteParticipantStatus = <T>(status: T | RemoteParticipantStatus) => {
  if (Object.values(RemoteParticipantStatus).includes(status as RemoteParticipantStatus))
    return participantStatusMapping[status as RemoteParticipantStatus]
  return status as T
}

export interface Participant {
  id: string
  datacaptId: string
  firstName: string
  lastName: string
  email: string
  internationalPhoneNumber?: InternationalPhoneNumber
  appliedDate: Date
  progress: number
  status: ParticipantStatus
  language: string
  centerId: string
  centerAbbreviation?: string
  note: string
  variableAnswers?: Record<TableColumnVariableSource, Record<string, SimpleAnswer>>
  photoThumbnail: string
}

export interface RemoteParticipant {
  id: number
  datacapt_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  language: string
  applied_date: string
  status: RemoteParticipantStatus
  progress: number
  center_id: number
  note: string
  photo_thumbnail: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variable_answers?: Record<TableColumnVariableSource, Record<string, any>>
}

interface FetchParticipantResponse {
  count: number
  next: number
  results: RemoteParticipant[]
}

const parseRemoteParticipant: (participant: RemoteParticipant) => Participant = (participant: RemoteParticipant) => ({
  id: String(participant.id),
  datacaptId: participant.datacapt_id,
  firstName: participant.first_name,
  lastName: participant.last_name,
  email: participant.email,
  internationalPhoneNumber: parseRemotePhoneNumber(participant.phone),
  language: participant.language,
  appliedDate: participant.applied_date ? new Date(participant.applied_date) : undefined,
  progress: participant.progress,
  status: participantStatusMapping[participant.status],
  centerId: String(participant.center_id),
  note: participant.note,
  photoThumbnail: participant.photo_thumbnail,
  variableAnswers:
    participant.variable_answers &&
    (Object.fromEntries(
      (Object.keys(participant.variable_answers) as TableColumnVariableSource[]).map(source => {
        return [
          source,
          source
            ? {
                ...Object.entries(participant.variable_answers[source]).reduce(
                  (acc, [key, value]) => ({ ...acc, [key]: parseRemoteSimpleAnswer(value) }),
                  {}
                )
              }
            : {}
        ]
      })
    ) as Record<TableColumnVariableSource, Record<string, SimpleAnswer>>)
})

export interface ParticipantsSorter {
  field: keyof Participant
  order: SorterOrder
}

const sorterFields = {
  email: ['subject__first_name', 'subject__last_name', 'subject__email'],
  stage: ['status'],
  appliedDate: ['applied_date'],
  survey: ['progress'],
  centerAbbreviation: ['center__abbreviation']
}

interface FetchParticipantsOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: ParticipantsSorter
    search?: string
    filters?: {
      status: string[]
    }
  }
}

interface FetchParticipantsResponseHandlers {
  onSuccess?: ({ participants, countAll }: { participants: Participant[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchParticipants = (
  { studyId, options }: FetchParticipantsOptions,
  responseHandlers?: FetchParticipantsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, ParticipantsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status?.map(s => participantStatusForSaveMapping[s as ParticipantStatus])
  }

  const { req, cancel } = fetchApi.get<FetchParticipantResponse>(`recruitment/studies/${studyId}/records/list`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchParticipantsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        participants: body.results.map(parseRemoteParticipant),
        countAll: body.count
      })
    }
  })

  return cancel
}

interface FetchAllSelectedParticipantsOptions {
  studyId: string
  options?: {
    search?: string
    status: string[]
  }
}
interface FetchAllSelectedParticipantsResponseHandlers {
  onSuccess?: (participants: Participant[]) => void
  onRequestError?: (code: number) => void
}

export const fetchAllSelectedParticipants = (
  { studyId, options }: FetchAllSelectedParticipantsOptions,
  responseHandlers?: FetchAllSelectedParticipantsResponseHandlers
) => {
  const query = {
    search: options.search,
    status: options.status
  }

  const { req, cancel } = fetchApi.get<RemoteParticipant[]>(`recruitment/studies/${studyId}/records/list/full`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAllSelectedParticipantsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteParticipant))
    }
  })

  return cancel
}

interface FetchParticipantOptions {
  participantId: string
  studyId: string
}

interface FetchParticipantResponseHandlers {
  onSuccess?: ({ participant }: { participant: Participant }) => void
  onRequestError?: (code: number) => void
}

export const fetchParticipant = (
  { participantId, studyId }: FetchParticipantOptions,
  responseHandlers?: FetchParticipantResponseHandlers
) => {
  // todo: add support to fetch without studyId
  const { req, cancel } = fetchApi.get<RemoteParticipant>(`recruitment/studies/${studyId}/records/${participantId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchParticipantResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        participant: parseRemoteParticipant(body)
      })
    }
  })

  return cancel
}

interface UpdateParticipantStatusOptions {
  participantId: string
  status: ParticipantStatus
  studyId: string
}

interface UpdateParticipantStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateParticipantStatus = (
  { participantId, status, studyId }: UpdateParticipantStatusOptions,
  responseHandlers: UpdateParticipantStatusResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`recruitment/studies/${studyId}/records/${participantId}`, {
    status: participantStatusForSaveMapping[status]
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateParticipantStatusResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateParticipantNoteOptions {
  participantId: string
  note: string
  studyId: string
}

interface UpdateParticipantNoteResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateParticipantNote = (
  { participantId, note, studyId }: UpdateParticipantNoteOptions,
  responseHandlers: UpdateParticipantNoteResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`recruitment/studies/${studyId}/records/${participantId}`, { note })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateParticipantNoteResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface InviteParticipantsOptions {
  emails: string[]
  subjectIds: string[]
  centerId?: string
  studyId: string
  isSendingInvitations: boolean
}

interface InviteParticipantsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const inviteParticipants = (
  { emails, subjectIds, centerId, studyId, isSendingInvitations }: InviteParticipantsOptions,
  responseHandlers?: InviteParticipantsResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`invitations/recruitment/${studyId}/invite`, {
    emails,
    datacapt_ids: subjectIds,
    center: centerId,
    send_invitations: isSendingInvitations
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<InviteParticipantsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchSubjectsNotInRecruitmentOptions {
  studyId: string
  searchPhrase: string
}

interface FetchSubjectsNotInRecruitmentResponse {
  results: RemoteSubjectInRepository[]
}

interface FetchSubjectsNotInRecruitmentResponseHandlers {
  onSuccess?: (subjects: SubjectInRepository[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectsNotInRecruitment = (
  { studyId, searchPhrase: search }: FetchSubjectsNotInRecruitmentOptions,
  responseHandlers: FetchSubjectsNotInRecruitmentResponseHandlers
) => {
  const url = `recruitment/studies/${studyId}/invite`
  const { req, cancel } = fetchApi.get<FetchSubjectsNotInRecruitmentResponse>(url, { search })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsNotInRecruitmentResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.results?.map(subject => parseRemoteSubject(subject)))
    }
  })

  return cancel
}

interface BulkUpdateParticipantsStatusResponseHandlers {
  onSuccess?: () => void
  onWrongStatus?: () => void
  onNoCurrency?: () => void
  onRequestError?: (code: number) => void
}

interface BulkUpdateParticipantsStatusOptions {
  search: string
  records: SelectionKeys
  status: string[]
  studyId: string
  targetStatus: ParticipantStatus
  paymentAmount: number
}

export const bulkUpdateParticipantsStatus = (
  { targetStatus, paymentAmount, search, status, records, studyId }: BulkUpdateParticipantsStatusOptions,
  responseHandlers?: BulkUpdateParticipantsStatusResponseHandlers
) => {
  const path = `recruitment/studies/${studyId}/records/status`
  const query = {
    search: search || undefined,
    records,
    status: status?.length ? status.join(',') : undefined,
    target_status: participantStatusForSaveMapping[targetStatus],
    payment_amount: paymentAmount
  }
  const { req, cancel } = fetchApi.post(path, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<BulkUpdateParticipantsStatusResponseHandlers>(
        {
          [BackendError.RECRUITMENT_RECORD_STATUS_ILLEGAL_TRANSITION]: 'onWrongStatus',
          [BackendError.PAYMENT_NO_CURRENCY]: 'onNoCurrency'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
